<template>
    <div class="body">

        <div v-if="exibir"class="mobz" style="padding-top: 20px; display: flex;">

            <div class="wmob" style="width: 50%; display: flex; flex-direction: column; align-items: center; ">
                <img src="../assets/img/Grupo 2.png" style="width: 300px;" class="sasasa">
                <p class="sasa" style="width: 390px;"><strong style="font-family: Bold;">Sua opinião é essencial para
                        melhorarmos nossas comunicações e promoções.</strong> Responda a esta pesquisa rápida e nos
                    ajude a tornar o Zé Express ainda melhor para você. <strong style="font-family: Bold;">Leva só
                        alguns minutos!</strong></p>
            </div>

            <div class="momo" v-if="!pesquisaFinalizada"
                style="min-width: 36%; padding: 20px; border-radius: 15px; background-color: white;     margin: 15px;">
                <div class="questions">

                    <div v-for="(pergunta, index) in this.items.perguntas" :key="pergunta.idPergunta"
                        style="max-width: 62%;" class="mobbbbbb">


                        <div v-if="index == 5" style="display: flex; gap: 10px;">
                            <label style="width: 10%; font-size: 36px"><strong style="font-family: 'Bold';">{{ index + 1
                                    }}.</strong></label>
                            <div style="width: 80%;">
                                <p style="font-family: 'Bold'"><strong>{{ pergunta.descricao }}</strong></p>
                                <div v-for="(resposta, index) in pergunta.respostas" :key="resposta.idResposta"
                                    style="display: flex; align-items: start; margin-bottom: 10px;">
                                    <input type="text"
                                        style="margin-top: 10px;     height: 28px;        width: 60%;        border: 1px solid black;    border-radius: 6px;"
                                        placeholder="Escreva aqui" v-model="resposta7">
                                </div>
                            </div>
                        </div>


                        <div v-else-if="index != 3" style="display: flex;">
                            <label style="width: 10%; font-size: 36px"><strong style="font-family: 'Bold';">{{ index + 1
                                    }}.</strong></label>
                            <div style="width: 80%;">
                                <p style="font-weight: bold;font-family: 'Bold'"><strong style="font-weight: bold">{{
                                        pergunta.descricao }}</strong></p>
                                <div v-for="(resposta, index) in pergunta.respostas" :key="resposta.idResposta"
                                    style="display: flex; align-items: start; margin-bottom: 10px;">
                                    <input type="radio" :name="'pergunta' + pergunta.idPergunta"
                                        :value="resposta.idResposta"
                                        :checked="respostasSelecionadas[pergunta.idPergunta] && respostasSelecionadas[pergunta.idPergunta].idResposta === resposta.idResposta"
                                        @change="atualizarRespostas(pergunta, resposta.idResposta)">
                                    <label style="margin-left: 5px">{{ resposta.descricao }}</label>
                                </div>
                            </div>
                        </div>


                        <div v-else style="display: flex;">
                            <label style="width: 10%; font-size: 36px"><strong style="font-family: 'Bold';">{{ index + 1
                                    }}.</strong></label>
                            <div style="width: 90%;">
                                <p style="font-family: 'Bold'"><strong>{{ pergunta.descricao }}</strong></p>
                                <div v-for="(resposta, index) in pergunta.respostas" :key="resposta.idResposta"
                                    style="display: flex; align-items: start; margin-bottom: 10px; flex-direction: column;">
                                    <div style="    display: flex;align-items: start;">
                                        <input type="checkbox" :value="resposta.idResposta"
                                            :checked="respostasSelecionadas[pergunta.idPergunta] && respostasSelecionadas[pergunta.idPergunta].some(r => r.idResposta === resposta.idResposta)"
                                            :disabled="respostasSelecionadas[pergunta.idPergunta] && respostasSelecionadas[pergunta.idPergunta].length >= 2 && !respostasSelecionadas[pergunta.idPergunta].some(r => r.idResposta === resposta.idResposta)"
                                            @change="atualizarSelecao(pergunta.idPergunta, resposta.idResposta)">
                                        <label style="min-width: 140px; margin-left: 5px" for="">{{ resposta.descricao
                                            }}</label>
                                    </div>
                                    <!-- Exibe o campo de texto apenas se o checkbox de 'Outros. Quais:' estiver selecionado -->
                                    <input
                                        style="margin-top: 10px;     height: 28px;        width: 60%;        border: 1px solid black;    border-radius: 6px;"
                                        v-if="resposta.descricao === 'Outros. Quais:' && respostasSelecionadas[pergunta.idPergunta] && respostasSelecionadas[pergunta.idPergunta].some(r => r.idResposta === resposta.idResposta)"
                                        type="text" placeholder="Escreva aqui"
                                        @input="handleDescricaoOutros(pergunta.idPergunta, $event)">
                                </div>
                            </div>
                        </div>


                    </div>
                    <div v-if="mensagemrespondertudo" class="hahahahehehe">
                        <p><strong>*Obrigatório responder todas as perguntas</strong></p>
                    </div>
                    <div
                        style="display: flex; flex-direction: column; align-items: center; width: 100%; justify-content: center; margin-top: 30px;">
                        <button @click="sendQuestions()"
                            style="border: none; background-color:#FF0090; color: white; border-radius: 5px; width: 180px; height: 30px; font-size: 18px;"><strong>ENVIAR</strong></button>
                    </div>

                </div>
            </div>
            <div v-else>
                <img src="../assets/img/gracias.png" style="margin-top: 5%; width: 350px;">
            </div>

        </div>
        
        <div v-else
        class="loading-circle">
            </div>



    </div>
</template>

<script>
import api from '../api';

export default {
    name: 'Enquete',
    data() {
        return {
        exibir: false,
            items: {
                perguntas: []
            },
            respostasSelecionadas: [], // Objeto para armazenar as respostas
            descricaoTextoOutros: {},
            pesquisaFinalizada: true,
            hash: '',
            mensagemrespondertudo: false,
            resposta7: '',
        };
    },
    mounted(){
        this.getHash()
        this.getQuestions();
    },
    methods: {
        atualizarSelecao(idPergunta, idResposta) {
            // Verifica se o array de respostas já existe
            if (!Array.isArray(this.respostasSelecionadas[idPergunta])) {
                this.respostasSelecionadas[idPergunta] = [];
            }

            const respostas = this.respostasSelecionadas[idPergunta];

            // Verifica se a resposta já está no array
            const index = respostas.findIndex(item => item.idResposta === idResposta);

            if (index === -1) {
                // Adiciona a resposta se o array tiver menos de 2 respostas
                if (respostas.length < 2) {
                    respostas.push({
                        idPergunta: idPergunta,
                        idResposta: idResposta
                    });
                } else {
                    alert('Você só pode selecionar até duas respostas.');
                }
            } else {
                // Remove a resposta se ela já estiver selecionada
                respostas.splice(index, 1);
            }
        },

        getQuestions() {
            api
                .get(`v1/Pesquisa/perguntas/${this.hash}`)
                .then((resp) => {
                    this.items = resp.data;
                    this.pesquisaFinalizada = false
                    this.exibir = true

                })
                .catch(() => {
                    this.pesquisaFinalizada = true
                    this.exibir = true
                });
        },
        sendQuestions() {
            const respostasParaEnviar = [];

            Object.entries(this.respostasSelecionadas).forEach(([idPergunta, respostas]) => {
                if (Array.isArray(respostas)) {
                    respostas.forEach(resposta => {
                        if (resposta.idResposta === 59 && this.descricaoTextoOutros[resposta.idPergunta]) {
                            respostasParaEnviar.push({
                                idPergunta: resposta.idPergunta,
                                idResposta: resposta.idResposta,
                                descricaoTexto: this.descricaoTextoOutros[resposta.idPergunta],
                                hash: this.hash
                            });
                        } else {
                            respostasParaEnviar.push({
                                idPergunta: resposta.idPergunta,
                                idResposta: resposta.idResposta,
                                descricaoTexto: null,
                                hash: this.hash
                            });
                        }
                    });
                } else {
                    if (respostas.idResposta === 59 && this.descricaoTextoOutros[respostas.idPergunta]) {
                        respostasParaEnviar.push({
                            idPergunta: respostas.idPergunta,
                            idResposta: respostas.idResposta,
                            descricaoTexto: this.descricaoTextoOutros[respostas.idPergunta],
                            hash: this.hash
                        });
                    } else {
                        respostasParaEnviar.push({
                            idPergunta: respostas.idPergunta,
                            idResposta: respostas.idResposta,
                            descricaoTexto: null,
                            hash: this.hash
                        });
                    }
                }
            });

            const uniqueIds = new Set(respostasParaEnviar.map(resposta => resposta.idResposta));

            const novoItem = {
                idPergunta: 21,
                idResposta: 59,
                descricaoTexto: this.resposta7,
                hash: this.hash
            }
            respostasParaEnviar.push(novoItem);
            const idRespostas = respostasParaEnviar.map(item => item.idPergunta);
            const uniqueIdRespostas = new Set(idRespostas);
            const resp6 = respostasParaEnviar.filter(r => r.idPergunta === 21)

            const item = respostasParaEnviar.find(
                r => r.idPergunta === 19 && r.idResposta === 59
            );




            if (uniqueIdRespostas.size > 5 && resp6.length > 0 && resp6[0].descricaoTexto) {

                if (!item || item.descricaoTexto !== null) {
                    api
                        .post('/v1/Pesquisa/respostas', respostasParaEnviar)
                        .then(() => {
                            this.pesquisaFinalizada = true;
                        })
                        .catch((error) => {
                            console.log(error);
                        });



                } else {
                    this.mensagemrespondertudo = true
                    setTimeout(() => {
                        this.mensagemrespondertudo = false
                    }, 1500);
                }

            }
            else {
                this.mensagemrespondertudo = true
                setTimeout(() => {
                    this.mensagemrespondertudo = false
                }, 1500);
            }



        },
        atualizarRespostas(pergunta, idResposta) {
            this.respostasSelecionadas[pergunta.idPergunta] = {
                idPergunta: pergunta.idPergunta,
                idResposta: idResposta
            };
        },
        handleDescricaoOutros(idPergunta, event) {
            this.descricaoTextoOutros[idPergunta] = event.target.value;
        },

        getHash() {
  const url = window.location.href;

  // Expressão regular para capturar 'hash=...' até encontrar /, ?, ou &
  const match = url.match(/[\?&]hash=([^\/?&#]*)/i);
  
  if (match) {
    this.hash = match[1]; // Captura o valor da hash
    console.log('Hash capturada:', this.hash);
  } else {
    this.hash = ''; // Nenhuma hash encontrada
    console.log('Nenhuma hash encontrada na URL.');
  }
}

    },

};
</script>



<style scoped>
.hahahahehehe {
    color: red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -5px;
    margin-bottom: -25px
}





@media(max-width: 850px) {
    .sasasa {
        width: 200 !important;
    }

    button {
        width: 125px !important;
    }
}

.questions {
    max-width: 500px;
}


.loading-circle {
  border: 9px solid rgba(255, 182, 193, 0.3); /* Rosa claro transparente */
  border-top: 9px solid #ff69b4; /* Rosa vibrante */
  border-radius: 50%;
  margin-left: 50%;
    position: fixed;
    top: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media(max-width: 850px) {
    .mobz {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .wmob {
        width: 100% !important;
    }

    .questions {
        max-height: none !important;
        height: auto !important;
        max-width: 500px;
    }



    .momo {
        min-width: 70% !important;
    }

    .sasa {
        width: 80% !important;
    }

    .sasasa {
        /* width: 300px !important; */
    }
}

input {
    border-radius: 5px; /* Remove o arredondamento */
}

.mobbbbbb {
    max-width: 93% !important;
}


@font-face {
    font-family: 'roboto';
    src: url('../assets/font/Roboto-Regular.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'bold';
    src: url('../assets/font/Roboto-Bold.ttf');
    font-weight: bold;
}

p,
label {
    font-family: 'roboto';
}

h1 {
    font-family: 'Bold'
}

.questions {
    background-color: white;
    min-height: 100px;
    max-height: 450px;
    overflow-y: auto;

}

button:hover {
    cursor: pointer;
}

.questions::-webkit-scrollbar {
    width: 8px;
}

.questions::-webkit-scrollbar-track {
    background: #ccc;
}

.questions::-webkit-scrollbar-thumb {
    background-color: #FFCC00;
}


input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-width: 18.5px;
    min-height: 18.5px;
    border: 1.5px solid black;
    outline: none;
    cursor: pointer;
    position: relative;
    background-color: white;
}


input[type="checkbox"]:checked {
    background-color: white;
    border-color: black;
}

input[type="checkbox"]:checked::before {
    content: '';
    display: block;
    min-width: 12px;
    min-height: 12px;
    background-color: #FF0090;
    position: absolute;
    top: 2px;
    left: 2px;
}


input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-width: 18.5px;
    min-height: 18.5px;
    border: 1.5px solid black;
    outline: none;
    cursor: pointer;
    position: relative;
    background-color: white;
}


input[type="radio"]:checked {
    background-color: white;
    border-color: black;
}

input[type="radio"]:checked::before {
    content: '';
    display: block;
    min-width: 12px;
    min-height: 12px;
    background-color: #FF0090;
    position: absolute;
    top: 2px;
    left: 2px;
}
</style>