<template>
<div>

  <div style="display: flex; align-items: center; justify-content: center; height: 7.5vh; position: fixed; top: 0; width: 100%; background-color: white;">
    <img src="./assets/img/ZEX.png" style="width: 130px;">
  </div>

  <div class="dada">
    <router-view/>
  </div>

  <div class="sasa" style="display: flex; align-items: center; justify-content: space-around; height: 7.5vh; padding-left: 10%; padding-right: 10%;">
    <p>Zé Express © Todos os direitos reservados.</p>
    <div style="display: flex; align-items: center;">
      <label>SIGA A GENTE!</label>
      <img  src="./assets/img/Grupo 4.png" @click="sendto('https://www.instagram.com/zeexpressbr/')" style="margin: 0px 5px; cursor: pointer;">
      <img style="cursor: pointer;" src="./assets/img/Grupo 5.png" @click="sendto('https://br.linkedin.com/company/zedelivery')">
    </div>
  </div>


</div>
</template>

<script>
  export default{
    methods:{
      sendto(link){
        window.open(link, '_blank');
      }
    }

  }


</script>



<style scoped>

@font-face {
    font-family: 'roboto';
    src: url('./assets/font/Roboto-Regular.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'bold';
    src: url('./assets/font/Roboto-Bold.ttf');
    font-weight: bold;
}

p, label{
    font-family: 'roboto';
}

.dada{
  background-image: url('./assets/img/bg.png');
    background-size: cover; /* Faz com que a imagem cubra toda a área */
    background-position: center; /* Centraliza a imagem no fundo */
    background-repeat: no-repeat; /* Evita que a imagem se repita */
    background-color:  #fff200ab ;
     height: 100vh;
     margin-top: 7.5vh;
     background-attachment: fixed; 

}

@media(max-width: 850px){
    .dada{
      background-image: url('./assets/img/bgmob.png');
      background-size: cover; /* Faz com que a imagem cubra toda a área */
    background-position: center; /* Centraliza a imagem no fundo */
    background-repeat: no-repeat; /* Evita que a imagem se repita */
      min-height: 100vh;
      height: auto !important
    }

    .sasa{
      flex-direction: column-reverse;
      height: 13vh !important;
      justify-content: center !important;
      padding: 0 !important;
    }

    p{
      margin: 0;
    }

}


</style>
